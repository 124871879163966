<template>
	<v-card :max-width="maxWidth"
		class="rounded-tl-0 rounded-bl rounded-br mx-auto"
	>
		<v-row no-gutters>
			<v-col cols="2">
				<v-img :src="require('@/assets/auth.jpg')"
					height="100%" width="100%"
					class="rounded-bl rounded-br-0"
				/>
			</v-col>
			<v-col cols="10" class="px-6">
				<div class="py-6"></div>
				<v-card-title class="px24 px-6">Login</v-card-title>
				<v-card-text>
					<div class="py-4">
						<text-field
							v-model="auth.username"
							icon="mdi-account"
							name="username"
							label="Username"
							:dense="false"
							@keyup="submitIfEnterPressed"
							:errors="formErrors"
						/>
					</div>
					<div class="py-4">
						<text-field
							v-model="auth.password"
							type="password"
							icon="mdi-lock"
							name="password"
							label="Password"
							:dense="false"
							@keyup="submitIfEnterPressed"
							:errors="formErrors"
						/>
					</div>
					<div class=" pt-4 d-flex justify-space-between flex-wrap">
						<v-btn disabled text x-small class="auth-text-btn" rounded>Forget Password?</v-btn>
						<v-btn disabled text x-small class="auth-text-btn" rounded>Register</v-btn>
					</div>
				</v-card-text>
				<v-card-text>
					<v-btn block color="primary"
						depressed rounded
						height="50" class="px18 font-bold"
						@click="login" :loading="loading"
					>
						Login
					</v-btn>
				</v-card-text>
				<div class="py-4"></div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import Snack from "@/mixins/Snack.js";
import {mapMutations} from "vuex";

export default {
	name: "LoginCard",
	props: { maxWidth: {type: String, default: "100%"}},
	mixins: [Snack],
	data: () => ({
		loading: false,
		auth: {
			username: null,
			password: null
		},
		formErrors: {}
	}),
	methods: {
		...mapMutations(["SET_LOGIN_STATE", "SET_CURRENT_USER"]),
		submitIfEnterPressed: function (e) {
			if (e.keyCode === 13) {
				this.login()
			}
		},
		login() {
			this.loading = true
			this.$axios.post(this.$urls.token, this.auth)
				.then(response => {
					this.$helper.setAccessToken(response.token)
					this.$axios.get(this.$urls.profile)
						.then((response) => {
							this.$helper.setCurrentUser(response[0])
							this.SET_CURRENT_USER(response[0])
							this.openSnack({text: "Logged in successfully", color: "success"})
							this.SET_LOGIN_STATE(false)
							this.$router.push({name: "Home"})
						})
						.catch(() => {
							this.openSnack({text: "Cannot fetch login information. Please try again."})
							this.$helper.clearAccessToken()
						})
				})
				.catch(e => {
					this.formErrors = {}
					const status = e.response.status
					if (status < 500 && status >= 400) {
						const nonFieldErrors = e.response.data["non_field_errors"]
						if (nonFieldErrors)
							this.openSnack({
								text: nonFieldErrors[0],
								timeout: 6000
							})
						else if (e.response.data) this.formErrors = e.response.data
						else
							this.openSnack({
								text: "Login failed. Please try again later."
							})
					}
				})
				.finally(() => this.loading = false)
		}
	}
}
</script>

<style scoped>

</style>
